import { Col, Container, Row } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { Link, Outlet } from "react-router-dom";

import LanguageSelector from "./ui/LanguageSelector";
import useSearchQuery from "../../hooks/useSearchQuery";

const TrialLayout = () => {
  const { t } = useTranslation(["general"]);
  const query = useSearchQuery();

  const comesFromForm = query.get("from") === "websiteform";

  return (
    <Container fluid style={{ height: "100dvh" }}>
      <Row className="d-flex align-items-stretch">
        <Col
          md={6}
          style={{ minHeight: "100dvh" }}
          className="d-flex flex-column align-items-stretch p-0 d-md-block d-none linear-background shadow-lg"
        >
          <a href="https://restomanager.net">
            <img
              src="/logo-white.svg"
              style={{
                maxWidth: "200px",
                position: "absolute",
                top: "30px",
                left: "30px",
              }}
              alt="RestoManager Logo"
            />
          </a>

          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <img
              src="/images/trial3.svg"
              alt="Restaurant Reservatie Software"
            />
          </div>
        </Col>
        <Col md={6} style={{ minHeight: "100dvh", backgroundColor: "white" }}>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="align-self-stretch d-flex justify-content-between mt-4 align-items-center">
              <div>
                <a
                  href="https://restomanager.net"
                  className="d-md-none d-block"
                >
                  <img
                    src="/logo.svg"
                    style={{
                      maxWidth: "150px",
                      position: "absolute",
                      top: "30px",
                      left: "30px",
                    }}
                    alt="RestoManager Logo"
                  />
                </a>
              </div>
              <div>
                <LanguageSelector
                  style={{
                    borderRadius: "7px",
                  }}
                />
              </div>
            </div>
            <div
              className="d-flex flex-column mt-3"
              style={{ width: "90%", maxWidth: "500px" }}
            >
              <h2 className="text-center mt-5 mb-3">
                <b>
                  <Trans
                    i18nKey={
                      comesFromForm
                        ? "general:trial.add.formTitle"
                        : "general:trial.add.title"
                    }
                    components={[<span style={{ color: "#7367F0" }} />]}
                  />
                </b>
              </h2>
              <h6 className="text-center mb-4">
                {t(
                  comesFromForm
                    ? "general:trial.add.formSubtitle"
                    : "general:trial.add.subtitle"
                )}
              </h6>

              <div className="d-flex justify-content-center align-items-center flex-grow-1 mt-3">
                <div style={{ width: "100%" }}>
                  <Outlet />
                </div>
              </div>

              <div className="d-flex gap-3 justify-content-center mt-5 flex-wrap">
                <div className="d-flex justify-content-center align-items-center">
                  <i
                    className="fa-solid fa-check me-2"
                    style={{
                      color: "#7367F0",
                      fontSize: "23px",
                    }}
                  />
                  <small>
                    <b>{t("general:trial.add.30daysFree")}</b>
                  </small>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <i
                    className="fa-solid fa-check me-2"
                    style={{
                      color: "#7367F0",
                      fontSize: "23px",
                    }}
                  />
                  <small>
                    <b>{t("general:trial.add.noCreditcard")}</b>
                  </small>
                </div>
              </div>

              <small className="text-center mt-3">
                <Trans
                  i18nKey="general:trial.add.alreadyHaveAccount"
                  components={[
                    <Link
                      to={{ pathname: "/" }}
                      style={{ color: "#00A3FF", textDecoration: "none" }}
                    />,
                  ]}
                />
              </small>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default TrialLayout;
