import { useEffect, useState } from "react";
import { Alert, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useSearchQuery from "../hooks/useSearchQuery";
import RegisterForm from "../components/register/RegisterForm";
import { Swal } from "../components/layout/ui";
import useFetch from "../hooks/useFetch";

const Register = () => {
  const { t } = useTranslation(["account"]);

  const navigate = useNavigate();
  const query = useSearchQuery();

  const email = query.get("email");
  const token = query.get("token");

  useEffect(() => {
    if (!email || !token) {
      navigate("/");
    }
  }, []);

  const [error, setError] = useState(null);

  const { fetchData: postRegister } = useFetch(
    "POST",
    ["account", "register"],
    false
  );

  const registerHandler = (password, firstname, lastname, telephone) => {
    postRegister(
      {},
      {
        inviteToken: token,
        email: email,
        password: password,
        firstname: firstname,
        lastname: lastname,
        telephone: telephone,
      }
    ).then((response) => {
      if (response.status === 200) {
        Swal.fire({
          title: t("register.success"),
          icon: "success",
        }).then(() => {
          navigate("/");
        });

        return response.json();
      } else if (response.status === 404) {
        setError(t("errors.userHasNoInvite"));
        return null;
      } else {
        setError(t("errors.couldNotValidate"));
        return null;
      }
    });
  };

  return (
    <>
      <Row>
        <Col className="text-white">
          <h3>
            <b>{t("register.title")}</b>
          </h3>
          <p>{t("register.subtitle")}</p>
        </Col>
      </Row>

      {error && <Alert variant="danger"> {error} </Alert>}

      <RegisterForm email={email} onRegister={registerHandler} />
    </>
  );
};

export default Register;
