import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import Subscription from "../../pages/settings/business/Subscription";
import { Button, CallUs } from "./ui";

interface Props {
  onLogout: () => void;
}

const TrialEndLayout = ({ onLogout }: Props) => {
  const { t } = useTranslation(["general", "account"]);

  return (
    <Container
      style={{ backgroundColor: "#EFEFEF" }}
      className="mt-4 p-3 text-center"
    >
      <Row>
        <Col>
          <img
            src="/logo.svg"
            width="300px"
            className="pb-4"
            alt="RestoManager logo"
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h3>
            <b>{t("general:trial.expired")}</b>
          </h3>
          <h6>{t("general:trial.expiredInformation")}</h6>
        </Col>
      </Row>

      <Row className="p-3">
        <Subscription />
      </Row>

      <Row className="mt-3">
        <Col className="d-flex justify-content-center gap-2">
          <Button onClick={onLogout} isOutline>
            <i className="fa fa-right-from-bracket me-2" />{" "}
            {t("account:logout")}
          </Button>
          <CallUs />
        </Col>
      </Row>
    </Container>
  );
};

export default TrialEndLayout;
