import { Permissions } from "./Permissions";

export enum ModuleType {
  Vouchers = "VOUCHERS",
  Reservations = "RESERVATIONS",
  Clients = "CLIENTS",
  DigitalMenu = "DIGITALMENU",
  Newsletters = "NEWSLETTERS",
  Reviews = "REVIEWS",
}

export interface Module {
  moduleType: ModuleType;
  key: string;
  icon: string;
  path: string;
  permission: string;
}

export const Modules: Array<Module> = [
  {
    moduleType: ModuleType.Clients,
    key: "clients",
    icon: "fa-book",
    path: "/modules/clients",
    permission: Permissions.clientList,
  },
  {
    moduleType: ModuleType.Vouchers,
    key: "vouchers",
    icon: "fa-gift",
    path: "/modules/vouchers",
    permission: Permissions.voucherList,
  },
  {
    moduleType: ModuleType.Reservations,
    key: "reservations",
    icon: "fa-calendar-week",
    path: "/modules/reservations",
    permission: Permissions.reservationList,
  },
  {
    moduleType: ModuleType.Newsletters,
    key: "newsletters",
    icon: "fa-mail-bulk",
    path: "/modules/newsletters",
    permission: Permissions.newsletterList,
  },
  {
    moduleType: ModuleType.DigitalMenu,
    key: "digitalmenu",
    icon: "fa-qrcode",
    path: "/modules/digitalmenu",
    permission: Permissions.digitalMenuManage,
  },
  {
    moduleType: ModuleType.Reviews,
    key: "reviews",
    icon: "fa-ranking-star",
    path: "/modules/reviews",
    permission: Permissions.reviewList,
  },
];
