import React, { useEffect, useState } from "react";
import { Account } from "../../@types/account";
import {
  getLocalstorageItem,
  setLocalstorageItem,
} from "../services/localstorage-service";
import useFetch from "../hooks/useFetch";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface AccountContextType {
  account: Account | null;
  updateAccount: (values: Account) => void;
}

const AccountContext = React.createContext<AccountContextType | null>(null);

export const AccountContextProvider = ({ children }: { children: any }) => {
  const { t, i18n } = useTranslation(["account"]);
  const [account, setAccount] = useState<Account>(
    JSON.parse(getLocalstorageItem("account")!)
  );

  const { fetchData: getAccount } = useFetch("GET", ["account"]);
  const { fetchData: updateAccount } = useFetch("PUT", ["account"]);

  useEffect(() => {
    const fetchAccount = async () => {
      getAccount()
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            return null;
          }
        })
        .then((account) => {
          if (account) {
            setAccount(account);
            setLocalstorageItem("account", JSON.stringify(account));
            i18n.changeLanguage(account.language);
          }
        });
    };

    fetchAccount();
  }, []);

  const updateAccountHandler = (account: Account) => {
    return updateAccount({}, account)
      .then((response) => {
        if (response.status === 200) {
          toast.success(t("account:update.success"));

          return response.json();
        } else {
          toast.error(t("account:update.error"));

          return null;
        }
      })
      .then((account) => {
        if (account) {
          setAccount(account);
          setLocalstorageItem("account", JSON.stringify(account));
        }
      });
  };

  const contextValue: AccountContextType = {
    account: account,
    updateAccount: updateAccountHandler,
  };

  return (
    <AccountContext.Provider value={contextValue}>
      {children}
    </AccountContext.Provider>
  );
};

export default AccountContext;
