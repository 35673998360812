import { createRoot } from "react-dom/client";
import App from "./App";
import { AuthContextProvider } from "./store/auth-context";
import Container from "../Container";
import * as Sentry from "@sentry/react";
import { Config } from "./Config";
import { CookiesProvider } from "react-cookie";

/**
 * Sentry.io
 */
Config.mode === "production" &&
  Sentry.init({
    dsn: "https://a0dbb022ba274563ae50129f1cce96cb@o1189074.ingest.sentry.io/6309333",
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  });

/**
 * Main application
 */
const rootElement = document.getElementById("root");
createRoot(rootElement!).render(
  <CookiesProvider defaultSetOptions={{ path: "/" }}>
    <AuthContextProvider>
      <Container>
        <App />
      </Container>
    </AuthContextProvider>
  </CookiesProvider>
);
