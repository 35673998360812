import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Field, Form } from "react-final-form";

import { Button, Input } from "../layout/ui";
import Validators from "../../utils/Validators";

const RegisterForm = ({ email, onRegister }) => {
  const { t } = useTranslation(["account", "general"]);

  const onSubmitHandler = (values) => {
    onRegister(
      values.password,
      values.firstname,
      values.lastname,
      values.telephone
    );
  };

  return (
    <Form
      onSubmit={onSubmitHandler}
      initialValues={{
        email: email,
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field name="email">
                {({ input, meta }) => (
                  <Input {...input} meta={meta} type="email" disabled />
                )}
              </Field>
            </Col>
          </Row>

          <Row>
            <Col>
              <Field
                name="password"
                validate={Validators.compose(
                  Validators.minLength(5),
                  Validators.maxLength(200)
                )}
              >
                {({ input, meta }) => (
                  <Input
                    {...input}
                    meta={meta}
                    type="password"
                    placeholder={t("general:fields.yourPassword")}
                  />
                )}
              </Field>
            </Col>
          </Row>

          <Row>
            <Col className="pe-0">
              <Field
                name="firstname"
                validate={Validators.compose(
                  Validators.required(),
                  Validators.maxLength(200)
                )}
              >
                {({ input, meta }) => (
                  <Input
                    {...input}
                    meta={meta}
                    type="text"
                    placeholder={t("general:fields.yourFirstname")}
                  />
                )}
              </Field>
            </Col>

            <Col>
              <Field
                name="lastname"
                validate={Validators.compose(
                  Validators.required(),
                  Validators.maxLength(200)
                )}
              >
                {({ input, meta }) => (
                  <Input
                    {...input}
                    meta={meta}
                    type="text"
                    placeholder={t("general:fields.yourLastname")}
                  />
                )}
              </Field>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Field
                name="telephone"
                validate={Validators.compose(
                  Validators.required(),
                  Validators.telephone(),
                  Validators.maxLength(200)
                )}
              >
                {({ input, meta }) => (
                  <Input
                    {...input}
                    meta={meta}
                    type="text"
                    placeholder={t("general:fields.yourTelephone")}
                  />
                )}
              </Field>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                type="submit"
                isBlock
                data-test="register"
                style={{ borderColor: "white" }}
              >
                {t("account:register.registerNow")}
              </Button>
            </Col>
          </Row>
        </form>
      )}
    />
  );
};

export default RegisterForm;
