import { useTranslation } from "react-i18next";
import {
  Subscription,
  SubscriptionType,
} from "../../../../@types/subscription";
import { Card } from "../../layout/ui";
import SubscriptionButton from "../SubscriptionButton";
import SubscriptionFeature from "../SubscriptionFeature";

interface SubscriptionItemProps {
  activeSubscription?: Subscription;
  handleNextStep?: (subscriptionType: SubscriptionType) => void;
}

export const SubscriptionFeaturesFree = () => {
  return (
    <>
      <SubscriptionFeature featureKey="vouchers" />
      <SubscriptionFeature featureKey="marketingCampaigns" amount={250} />
      <SubscriptionFeature featureKey="amountPerOnlinePayment" amount={2.99} />
    </>
  );
};

const SubscriptionFree = ({
  activeSubscription,
  handleNextStep,
}: SubscriptionItemProps) => {
  const { t } = useTranslation(["settings", "general"]);

  return (
    <Card
      title={t("settings:business.subscription.type.free")}
      subtitle={t("settings:business.subscription.type.freeInfo")}
      className="mb-3 w-100"
      forCurrent={activeSubscription?.type === SubscriptionType.Free}
      style={{ maxWidth: "1085px" }}
    >
      <div className="d-flex flex-column gap-2">
        <SubscriptionFeaturesFree />
        {handleNextStep && (
          <SubscriptionButton
            currentSubscription={activeSubscription}
            forSubscription={SubscriptionType.Free}
            handleNextStep={handleNextStep}
          />
        )}
      </div>
    </Card>
  );
};

export default SubscriptionFree;
