import { Col, Row } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";

import { Trans } from "react-i18next";
import Validators from "../../utils/Validators";
import { Button, Input } from "../layout/ui";

export type TrialFormValues = {
  name: string;
  email: string;
  password: string;
};

interface Props {
  onSubmit: (values: TrialFormValues) => void;
  isLoading: boolean;
}

const TrialForm = ({ onSubmit, isLoading }: Props) => {
  const { t } = useTranslation(["general"]);

  const onSubmitHandler = (values: TrialFormValues) => {
    onSubmit(values);
  };

  return (
    <Form
      onSubmit={onSubmitHandler}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col>
              <Field
                name="name"
                validate={Validators.compose(
                  Validators.required(),
                  Validators.maxLength(200)
                )}
              >
                {({ input, meta }) => (
                  <label style={{ width: "100%" }}>
                    {t("general:fields.businessName")}
                    <Input
                      {...input}
                      meta={meta}
                      type="text"
                      style={{ height: "45px" }}
                      disableSpacing
                    />
                  </label>
                )}
              </Field>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Field
                name="email"
                validate={Validators.compose(
                  Validators.required(),
                  Validators.email(),
                  Validators.maxLength(500)
                )}
              >
                {({ input, meta }) => (
                  <label style={{ width: "100%" }}>
                    {t("general:fields.email")}
                    <Input
                      {...input}
                      meta={meta}
                      type="email"
                      style={{ height: "45px" }}
                      disableSpacing
                    />
                  </label>
                )}
              </Field>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col>
              <Field
                name="password"
                validate={Validators.compose(
                  Validators.minLength(5),
                  Validators.maxLength(200)
                )}
              >
                {({ input, meta }) => (
                  <label style={{ width: "100%" }}>
                    {t("general:fields.password")}
                    <Input
                      {...input}
                      meta={meta}
                      type="password"
                      style={{ height: "45px" }}
                      disableSpacing
                    />
                  </label>
                )}
              </Field>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                type="submit"
                isBlock
                isLoading={isLoading}
                disabled={isLoading}
                data-test="submit-trial"
                style={{ fontSize: "20px", borderColor: "white" }}
              >
                <b>{t("general:trial.add.button")}</b>
              </Button>
            </Col>
          </Row>

          <Row>
            <Col>
              <small className="text-muted" style={{ fontSize: "12px" }}>
                <Trans
                  i18nKey="general:conditions"
                  components={[
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a
                      href="https://restomanager.net/legal/"
                      target="_blank"
                      style={{ textDecoration: "underline" }}
                      rel="noreferrer"
                    />,
                  ]}
                />
              </small>
            </Col>
          </Row>
        </form>
      )}
    />
  );
};

export default TrialForm;
