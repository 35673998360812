import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Colors } from "../../../../@types/colors";
import { PromotionalCode } from "../../../../@types/promotionalCode";
import {
  Subscription as SubscriptionModel,
  SubscriptionSequence,
  SubscriptionType,
} from "../../../../@types/subscription";
import { Config } from "../../../Config";
import {
  BackButton,
  Button,
  Card,
  Input,
  Swal,
} from "../../../components/layout/ui";
import CurrentSubscription from "../../../components/subscriptions/CurrentSubscription";
import SubscriptionCancel from "../../../components/subscriptions/SubscriptionCancel";
import SubscriptionCheckout from "../../../components/subscriptions/SubscriptionCheckout";
import SubscriptionList from "../../../components/subscriptions/SubscriptionList";
import useFetch from "../../../hooks/useFetch";
import AuthContext from "../../../store/auth-context";

enum ViewState {
  Overview,
  Upgrade,
  Checkout,
  CancelSubscription,
  CancelSubscriptionPending,
}

export interface SelectedSubscriptionModel {
  type: SubscriptionType;
  sequence: SubscriptionSequence;
  promotionalCode?: PromotionalCode;
}

const Subscription = () => {
  const authContext = useContext(AuthContext);
  const { t } = useTranslation(["settings", "general"]);
  const navigate = useNavigate();

  const [viewState, setViewState] = useState<ViewState>(ViewState.Overview);
  const [subscriptions, setSubscriptions] = useState<
    SubscriptionModel[] | null
  >(null);
  const [showAsUpgradeFrom, setShowAsUpgradeFrom] = useState<
    SubscriptionType | undefined
  >(undefined);

  const [selectedSubscription, setSelectedSubscription] =
    useState<SelectedSubscriptionModel | null>(null);

  const subscriptionType = authContext?.business?.subscriptionType!;
  const activeSubscriptions = subscriptions?.filter(
    (s) => s.activePayment !== null
  );
  const getSubscriptionActive =
    (subscriptionType === SubscriptionType.Basic ||
      subscriptionType === SubscriptionType.Growing ||
      subscriptionType === SubscriptionType.Professional) &&
    subscriptions &&
    subscriptions.find((s) => s.activePayment !== null);

  const { fetchData: getSubscriptions } = useFetch("GET", ["subscription"]);
  const {
    fetchData: createSubscription,
    loading: isCreateSubscriptionLoading,
  } = useFetch("POST", ["subscription"]);
  const {
    fetchData: updateSubscription,
    loading: isUpdateSubscriptionLoading,
  } = useFetch("PUT", ["subscription"]);
  const { fetchData: cancelSubscription } = useFetch("DELETE", [
    "subscription",
  ]);
  const { fetchData: getPromotionalCode } = useFetch("GET", [
    "promotionalcode",
    "forbusiness",
  ]);

  useEffect(() => {
    getSubscriptions()
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          return null;
        }
      })
      .then((subs) => {
        if (subs) {
          setSubscriptions(subs);
        }
      });
  }, []);

  const handleGoToCheckout = (
    type: SubscriptionType,
    sequence: SubscriptionSequence,
    showAsUpgradeFrom?: SubscriptionType
  ) => {
    setSelectedSubscription({ type, sequence });
    setViewState(ViewState.Checkout);
    setShowAsUpgradeFrom(showAsUpgradeFrom);
  };

  const handlePromotionalCode = (code: string) => {
    getPromotionalCode({
      code,
      subscriptionType: selectedSubscription!.type,
      subscriptionSequence: selectedSubscription!.sequence,
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return null;
        }
      })
      .then((promotionalCode) => {
        if (promotionalCode) {
          setSelectedSubscription((prev) => ({ ...prev!, promotionalCode }));
        } else {
          toast.error(
            t("settings:business.subscription.promotionCodeNotFound")
          );
        }
      });
  };

  const handlePayment = (currentSubscriptionId?: string) => {
    if (selectedSubscription) {
      if (currentSubscriptionId) {
        updateSubscription(
          {},
          {
            subscriptionId: currentSubscriptionId,
            subscriptionType: selectedSubscription.type,
            sequence: selectedSubscription.sequence,
            ...(selectedSubscription.promotionalCode
              ? { promotionalCode: selectedSubscription.promotionalCode.code }
              : {}),
          }
        ).then((response) => {
          if (response.status === 200) {
            Swal.fire({
              title: t(
                "settings:business.subscription.upgradeSubscription.success"
              ),
              icon: "success",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/dashboard");
              }
            });
          } else {
            return null;
          }
        });
      } else {
        createSubscription(
          {},
          {
            subscriptionType: selectedSubscription.type,
            sequence: selectedSubscription.sequence,
            ...(selectedSubscription.promotionalCode
              ? { promotionalCode: selectedSubscription.promotionalCode.code }
              : {}),
          }
        )
          .then((response) => {
            if (response.status === 200) {
              return response.text();
            } else {
              return null;
            }
          })
          .then((url) => {
            if (url) {
              if (url.includes(Config.baseUrl)) {
                authContext?.setBusiness({
                  ...authContext?.business!,
                  subscriptionType: selectedSubscription.type,
                });
              }

              window.location.href = url.replace(/"/g, "");
            }
          });
      }
    }
  };

  const handleCancelSubscription = () => {
    Swal.fire({
      title: t("general:confirmation.areYouSure"),
      text: t("general:confirmation.areYouSureDesc"),
      icon: "warning",
      confirmButtonText: t("general:confirmation.confirm"),
      denyButtonText: t("general:confirmation.deny"),
      showDenyButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        cancelSubscription({
          businessId: authContext?.business?.id,
          subscriptionId: subscriptions?.find((s) => s.activePayment !== null)
            ?.id,
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              return null;
            }
          })
          .then((business) => {
            if (business) {
              setViewState(ViewState.CancelSubscriptionPending);
            }
          });
      }
    });
  };

  return (
    <Container>
      {viewState === ViewState.Overview && subscriptions && (
        <>
          {getSubscriptionActive ? (
            <CurrentSubscription
              onUpgradeSubscription={() => setViewState(ViewState.Upgrade)}
              activeSubscription={activeSubscriptions![0]}
            />
          ) : (
            <SubscriptionList handleNextStep={handleGoToCheckout} />
          )}

          {getSubscriptionActive && (
            <Row>
              <Col className="text-end my-3 text-muted">
                <small
                  onClick={() => setViewState(ViewState.CancelSubscription)}
                  style={{ cursor: "pointer" }}
                >
                  {t("settings:business.subscription.cancel.title")}
                </small>
              </Col>
            </Row>
          )}

          {authContext?.role === "Admin" && getSubscriptionActive && (
            <SubscriptionAdminPanel subscriptions={subscriptions} />
          )}
        </>
      )}

      {viewState === ViewState.Upgrade && (
        <>
          <BackButton onClick={() => setViewState(ViewState.Overview)} />
          <SubscriptionList
            handleNextStep={handleGoToCheckout}
            activeSubscription={
              getSubscriptionActive ? getSubscriptionActive : undefined
            }
            showAsUpgrade
          />
        </>
      )}

      {viewState === ViewState.Checkout && (
        <>
          <BackButton onClick={() => setViewState(ViewState.Overview)} />

          <SubscriptionCheckout
            selectedSubscription={selectedSubscription!}
            handlePromotionalCode={handlePromotionalCode}
            handleCheckout={handlePayment}
            showAsUpgradeFrom={showAsUpgradeFrom}
            activeSubscription={
              getSubscriptionActive ? getSubscriptionActive : undefined
            }
            isLoading={
              isCreateSubscriptionLoading || isUpdateSubscriptionLoading
            }
          />
        </>
      )}

      {viewState === ViewState.CancelSubscription && (
        <>
          <BackButton onClick={() => setViewState(ViewState.Overview)} />
          <SubscriptionCancel
            onCancel={handleCancelSubscription}
            currentSubscription={subscriptionType}
          />
        </>
      )}

      {viewState === ViewState.CancelSubscriptionPending && (
        <>
          <Row>
            <Col>
              <Card
                title={t("settings:business.subscription.cancel.title")}
                subtitle={t("settings:business.subscription.cancel.subtitle")}
                icon="fas fa-times-circle"
              >
                <p>{t("settings:business.subscription.cancel.pending")}</p>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

const SubscriptionAdminPanel = ({
  subscriptions,
}: {
  subscriptions: SubscriptionModel[];
}) => {
  const authContext = useContext(AuthContext);
  const { t } = useTranslation(["settings", "general"]);
  const navigate = useNavigate();

  const [adminSigularPayment, setAdminSigularPayment] = useState<{
    amount: number;
    description: string;
  } | null>(null);

  const { fetchData: cancelAdminSubscription } = useFetch("DELETE", [
    "subscription",
    "confirm",
  ]);
  const { fetchData: chargeOneOff } = useFetch("POST", [
    "subscription",
    "oneoff",
  ]);

  const handleAdminCancelSubscription = () => {
    Swal.fire({
      title: t("general:confirmation.areYouSure"),
      text: t("general:confirmation.areYouSureDesc"),
      icon: "warning",
      confirmButtonText: t("general:confirmation.confirm"),
      denyButtonText: t("general:confirmation.deny"),
      showDenyButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        cancelAdminSubscription({
          businessId: authContext?.business?.id,
          subscriptionId: subscriptions?.find((s) => s.activePayment !== null)
            ?.id,
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              return null;
            }
          })
          .then((business) => {
            if (business) {
              authContext?.setBusiness(business);
              navigate("/dashboard");
            }
          });
      }
    });
  };

  const handleAdminChargeOneOff = () => {
    Swal.fire({
      title: t("general:confirmation.areYouSure"),
      text: t("general:confirmation.areYouSureDesc"),
      icon: "warning",
      confirmButtonText: t("general:confirmation.confirm"),
      denyButtonText: t("general:confirmation.deny"),
      showDenyButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        chargeOneOff({
          businessId: authContext?.business?.id,
          description: adminSigularPayment?.description,
          amount: adminSigularPayment?.amount,
        }).then((response) => {
          if (response.status === 200) {
            toast.success("Payment successful");
          } else {
            toast.error("Payment failed");
          }
        });
      }
    });
  };

  return (
    <>
      <hr className="mb-4" />
      <Row>
        <Col md={6} className="mb-3">
          <Card
            title="Charge singular payment"
            subtitle="Charge a singular payment to the business."
            forAdmin
            icon="fas fa-dollar-sign"
          >
            <Row>
              <Col>
                <Input
                  type="number"
                  placeholder="Amount"
                  label="Amount"
                  onChange={(e: any) =>
                    setAdminSigularPayment((prev) => ({
                      ...prev!,
                      amount: parseFloat(e.target.value),
                    }))
                  }
                />
                <Col>
                  <Input
                    type="text"
                    placeholder="Description"
                    label="Description"
                    onChange={(e: any) =>
                      setAdminSigularPayment((prev) => ({
                        ...prev!,
                        description: e.target.value,
                      }))
                    }
                  />
                </Col>
                <Col>
                  <Button
                    isBlock
                    color={Colors.Success}
                    onClick={handleAdminChargeOneOff}
                  >
                    Charge now
                  </Button>
                </Col>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md={6}>
          <Card
            title="Cancel Subscription"
            subtitle="Stop the current subscription."
            forAdmin
            icon="fas fa-stop-circle"
          >
            <Button
              isBlock
              color={Colors.Danger}
              onClick={handleAdminCancelSubscription}
            >
              Stop subscription
            </Button>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Subscription;
