import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Field, Form } from "react-final-form";
import { Link } from "react-router-dom";

import { Button, Input, Label } from "../layout/ui";
import Validators from "../../utils/Validators";

interface Props {
  onLogin: (email: string, password: string) => void;
  isLoading: boolean;
}

const LoginEmailPassword = ({ onLogin, isLoading }: Props) => {
  const { t } = useTranslation(["account", "general"]);

  const onSubmitHandler = (values: { email: string; password: string }) => {
    onLogin(values.email, values.password);
  };

  return (
    <Form
      onSubmit={onSubmitHandler}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Row className="mb-2">
            <Col>
              <Field
                name="email"
                validate={Validators.compose(
                  Validators.required(),
                  Validators.email()
                )}
              >
                {({ input, meta }) => (
                  <Label
                    text={t("general:fields.yourEmail")}
                    style={{ color: "white", width: "100%" }}
                  >
                    <Input
                      {...input}
                      meta={meta}
                      type="email"
                      style={{ height: "45px" }}
                      disableSpacing
                      disableErrorText
                    />
                  </Label>
                )}
              </Field>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Field name="password" validate={Validators.required()}>
                {({ input, meta }) => (
                  <Label
                    text={t("general:fields.yourPassword")}
                    style={{ color: "white", width: "100%" }}
                  >
                    <Input
                      {...input}
                      meta={meta}
                      type="password"
                      style={{ height: "45px" }}
                      disableSpacing
                      disableErrorText
                    />
                    <small style={{ fontSize: "12px" }}>
                      <Link
                        to="forgotpassword"
                        style={{ textDecoration: "underline", color: "white" }}
                      >
                        {t("account:login.emailPassword.forgotPassword")}
                      </Link>
                    </small>
                  </Label>
                )}
              </Field>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                type="submit"
                data-test="login"
                isBlock
                style={{ borderColor: "white" }}
                isLoading={isLoading}
                disabled={isLoading}
              >
                {t("login.emailPassword.next")}
              </Button>
            </Col>
          </Row>

          <Row>
            <Col className="mt-3 text-center">
              <Link
                to="trial"
                style={{
                  textDecoration: "none",
                  color: "white",
                  fontSize: "14px",
                }}
              >
                {t("account:login.emailPassword.noAccount")} ✨
              </Link>
            </Col>
          </Row>
        </form>
      )}
    />
  );
};

export default LoginEmailPassword;
