import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Field, Form } from "react-final-form";

import { Button, Input } from "../layout/ui";
import Validators from "../../utils/Validators";

const RequestPasswordForm = ({ onSubmit }) => {
  const { t } = useTranslation(["account", "general"]);

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                name="password"
                validate={Validators.compose(
                  Validators.required(),
                  Validators.minLength(5),
                  Validators.maxLength(200)
                )}
              >
                {({ input, meta }) => (
                  <Input
                    {...input}
                    meta={meta}
                    type="password"
                    placeholder={t("account:forgotPassword.password")}
                  />
                )}
              </Field>
            </Col>
          </Row>

          <Row>
            <Col>
              <Field
                name="confirmPassword"
                validate={Validators.compose(
                  Validators.required(),
                  Validators.minLength(5),
                  Validators.maxLength(200)
                )}
              >
                {({ input, meta }) => (
                  <Input
                    {...input}
                    meta={meta}
                    type="password"
                    placeholder={t("account:forgotPassword.passwordConfirm")}
                  />
                )}
              </Field>
            </Col>
          </Row>

          <Row>
            <Col className="mt-2">
              <Button
                type="submit"
                isBlock
                data-test="save-password"
                style={{ borderColor: "white" }}
              >
                {t("general:buttons.save")}
              </Button>
            </Col>
          </Row>
        </form>
      )}
    />
  );
};

export default RequestPasswordForm;
