import { useContext, useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import AuthContext from "../../../store/auth-context";
import { Link, useNavigate } from "react-router-dom";
import { Business } from "../../../../@types/business";

const BusinessPicker = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const businessList = useMemo(() => {
    return authContext!.businessList.filter((b) => b && b.isEnabled);
  }, [authContext!.businessList]);

  if (businessList.length === 1) {
    return (
      <Link to="/dashboard" style={{ textDecoration: "none", color: "black" }}>
        <b data-test="navigation-business-name">{authContext!.business.name}</b>
      </Link>
    );
  }

  const onBusinessChangeHandler = (business: Business) => {
    authContext!.setBusiness(business);
    navigate("/");
  };

  return (
    <Dropdown drop="down-centered">
      <Dropdown.Toggle
        variant="link"
        style={{ textDecoration: "none", color: "black" }}
      >
        <b>{authContext!.business.name}</b>
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ maxHeight: "400px", overflowY: "auto" }}>
        {businessList.map((business) => (
          <Dropdown.Item
            key={business.id}
            onClick={() => {
              onBusinessChangeHandler(business);
            }}
          >
            {business.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default BusinessPicker;
