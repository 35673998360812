import { Trans } from "react-i18next";

interface Props {
  featureKey: string;
  amount?: number;
}

const SubscriptionFeature = ({ featureKey, amount }: Props) => {
  return (
    <div className="d-flex flex-row" style={{ fontWeight: "500" }}>
      <i
        className="fa fa-check me-2 mt-1"
        style={{ color: "#7367F0", fontSize: "20px" }}
      />
      <div>
        <Trans
          i18nKey={`settings:business.subscription.features.${featureKey}`}
          values={{ amount }}
          components={[
            <br />,
            <div style={{ color: "#CECECE", fontSize: "14px" }} />,
          ]}
        />
      </div>
    </div>
  );
};

export default SubscriptionFeature;
