import { useTranslation } from "react-i18next";
import {
  Subscription,
  SubscriptionSequence,
  SubscriptionType,
} from "../../../../@types/subscription";
import { Card, MoneyFormat } from "../../layout/ui";
import SubscriptionFeature from "../SubscriptionFeature";
import SubscriptionButton from "../SubscriptionButton";
import { PRICING } from "./Packages";
import { useMemo } from "react";
import { formatMoney } from "../../layout/ui/MoneyFormat";

export const SubscriptionFeaturesGrowing = ({
  showAllFeatures,
}: {
  showAllFeatures?: boolean;
}) => {
  const { t } = useTranslation(["settings"]);

  return (
    <>
      {showAllFeatures ? (
        <>
          <SubscriptionFeature featureKey="reservations" />
          <SubscriptionFeature featureKey="vouchers" />
          <SubscriptionFeature featureKey="reserveWithGoogle" />
        </>
      ) : (
        <div className="d-flex flex-row mb-2">
          <i className="fa fa-plus me-2 mt-1 text-success" />
          <b>
            {t("settings:business.subscription.features.everythingFromBasic")}
          </b>
        </div>
      )}

      <SubscriptionFeature featureKey="reviews" />
      <SubscriptionFeature featureKey="digitalMenu" />
      <SubscriptionFeature featureKey="marketingCampaigns" amount={15000} />
      <SubscriptionFeature featureKey="amountPerOnlinePayment" amount={0.99} />
      <SubscriptionFeature featureKey="noComissionPerCover" />
    </>
  );
};

interface SubscriptionItemProps {
  displaySequenceType: SubscriptionSequence;
  activeSubscription?: Subscription;
  handleNextStep?: (subscriptionType: SubscriptionType) => void;
  showAsUpgradeFrom?: SubscriptionType;
}

const SubscriptionGrowing = ({
  displaySequenceType,
  activeSubscription,
  handleNextStep,
  showAsUpgradeFrom,
}: SubscriptionItemProps) => {
  const { t } = useTranslation(["settings", "general"]);

  const displayPrice = useMemo(() => {
    if (showAsUpgradeFrom === SubscriptionType.Basic) {
      return displaySequenceType === SubscriptionSequence.Monthly
        ? PRICING.GROWING.MONTHLY - PRICING.BASIC.MONTHLY
        : PRICING.GROWING.MONTHLY_PAID_ANNUALLY -
            PRICING.BASIC.MONTHLY_PAID_ANNUALLY;
    }

    return displaySequenceType === SubscriptionSequence.Monthly
      ? PRICING.GROWING.MONTHLY
      : PRICING.GROWING.MONTHLY_PAID_ANNUALLY;
  }, [displaySequenceType]);

  return (
    <Card
      title={t("settings:business.subscription.type.growing")}
      subtitle={t("settings:business.subscription.type.growingInfo")}
      className="mb-3 w-100"
      forCurrent={activeSubscription?.type === SubscriptionType.Growing}
      style={{ maxWidth: "350px" }}
    >
      <div
        className="d-flex flex-column justify-content-between"
        style={{ minHeight: "410px" }}
      >
        <div className="d-flex flex-column gap-2 mb-4">
          <div className="d-flex flex-column align-items-center mb-3">
            <h3 className="m-0">
              <MoneyFormat value={displayPrice} useDecimals={false} />
            </h3>
            <small className="text-muted">
              {t(
                showAsUpgradeFrom
                  ? "settings:business.subscription.perMonthExtra"
                  : "settings:business.subscription.perMonth"
              )}
            </small>
          </div>

          <SubscriptionFeaturesGrowing />
        </div>
        <div>
          {displaySequenceType === SubscriptionSequence.Quarterly && (
            <div className="d-flex justify-content-center">
              <small>
                {t("settings:business.subscription.quarterTotal", {
                  amount: formatMoney(PRICING.GROWING.QUARTERLY, "EUR", false),
                })}
              </small>
            </div>
          )}
          {displaySequenceType === SubscriptionSequence.Yearly && (
            <div className="d-flex justify-content-center">
              <small>
                {t("settings:business.subscription.yearTotal", {
                  amount: formatMoney(PRICING.GROWING.YEARLY, "EUR", false),
                })}
              </small>
            </div>
          )}
          {handleNextStep && (
            <SubscriptionButton
              currentSubscription={activeSubscription}
              forSubscription={SubscriptionType.Growing}
              handleNextStep={handleNextStep}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default SubscriptionGrowing;
