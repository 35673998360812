import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { IconButton } from "./ui";
import LanguageSelector from "./ui/LanguageSelector";

const AccountLayout = () => {
  const { t } = useTranslation(["general"]);

  return (
    <Container fluid style={{ height: "100dvh" }}>
      <Row className="d-flex align-items-stretch">
        <Col
          md={8}
          style={{ height: "100dvh" }}
          className="d-flex flex-column align-items-stretch p-0 d-md-block d-none"
        >
          <a href="https://restomanager.net">
            <img
              src="/logo.svg"
              style={{
                maxWidth: "100px",
                position: "absolute",
                top: "30px",
                left: "30px",
              }}
              alt="RestoManager Logo"
            />
          </a>

          <div
            className="d-flex justify-content-center align-items-end"
            style={{
              height: "35%",
            }}
          >
            <div className="d-flex flex-column pb-5 text-center">
              <h1 style={{ fontSize: "60px", color: "black" }}>
                <b>{t("general:welcome.title")}</b>
              </h1>
              <h5>{t("general:welcome.subtitle")}</h5>
            </div>
          </div>
          <div
            style={{
              height: "65%",
              backgroundImage: "url(/images/login.svg)",
              backgroundSize: "cover",
              backgroundPosition: "top",
              backgroundRepeat: "no-repeat",
            }}
          />
        </Col>
        <Col
          md={4}
          className="linear-background shadow-lg"
          style={{ height: "100dvh" }}
        >
          <a
            href="https://restomanager.net/support/"
            target="_blank"
            rel="noreferrer"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            <IconButton
              icon="fa-regular fa-circle-question"
              className="text-white"
              style={{ fontSize: "22px" }}
            />
          </a>

          <div
            className="d-flex flex-column justify-content-around px-5 py-2 flex-grow-1"
            style={{ height: "100%" }}
          >
            <Row className="d-flex justify-content-center align-items-end flex-grow-1">
              <img
                src="/logo-white.svg"
                style={{
                  maxWidth: "250px",
                }}
                alt="RestoManager logo"
              />
            </Row>

            <Row className="d-flex justify-content-center align-items-center flex-grow-1">
              <Col style={{ width: "100%", maxWidth: "400px" }}>
                <Outlet />
              </Col>
            </Row>

            <div className="d-flex justify-content-center align-items-end flex-grow-1">
              <LanguageSelector
                style={{
                  backgroundColor: "white",
                  borderRadius: "7px",
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AccountLayout;
