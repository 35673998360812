export enum SubscriptionType {
  Free = 0,
  Basic = 1,
  Growing = 2,
  Professional = 3,
  Trial = 4,
}

export enum SubscriptionSequence {
  Yearly = 0,
  Quarterly = 1,
  Monthly = 2,
}

export interface Subscription {
  id: string;
  activePayment: SubscriptionPayment | null;
  type: SubscriptionType;
  sequence: SubscriptionSequence;
  nextDueDate?: string | null;
  nextDueTotal: number;
  canceled?: string | null;
  created: string;
}

export interface SubscriptionPayment {
  molliePaymentId: string;
  method: string;
  status: string;
  description: string;
}
