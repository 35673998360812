import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import {
  Subscription,
  SubscriptionSequence,
  SubscriptionType,
} from "../../../@types/subscription";
import { Button } from "../layout/ui";
import SubscriptionBasic from "./packages/SubscriptionBasic";
import SubscriptionFree from "./packages/SubscriptionFree";
import SubscriptionGrowing from "./packages/SubscriptionGrowing";
import SubscriptionProfessional from "./packages/SubscriptionProfessional";
import { Colors } from "../../../@types/colors";

interface SubscriptionListProps {
  activeSubscription?: Subscription;
  showAsUpgrade?: boolean;
  handleNextStep: (
    type: SubscriptionType,
    sequence: SubscriptionSequence,
    showAsUpgradeFrom?: SubscriptionType
  ) => void;
}

const SubscriptionList = ({
  activeSubscription,
  showAsUpgrade,
  handleNextStep,
}: SubscriptionListProps) => {
  const { t } = useTranslation(["settings", "general"]);

  const [sequenceType, setSequenceType] = useState<SubscriptionSequence>(
    activeSubscription
      ? activeSubscription.sequence
      : SubscriptionSequence.Quarterly
  );

  const handleNextStepWrapper = (subscriptionType: SubscriptionType) => {
    handleNextStep(
      subscriptionType,
      sequenceType,
      showAsUpgrade ? activeSubscription?.type : undefined
    );
  };

  return (
    <>
      <Row>
        <h1 style={{ textAlign: "center", fontWeight: "700" }} className="my-4">
          <Trans
            i18nKey={`settings:business.subscription.listTitle`}
            components={[<span style={{ color: "#7367F0" }} />]}
          />
        </h1>
      </Row>

      <Row className="mb-3">
        <div className="d-flex justify-content-center flex-column align-items-center">
          <div className="d-flex gap-2 flex-wrap justify-content-center">
            <Button
              isOutline={sequenceType !== SubscriptionSequence.Yearly}
              onClick={() => setSequenceType(SubscriptionSequence.Yearly)}
              style={{ borderRadius: "50px" }}
              color="#130C60"
            >
              <b>{t("settings:business.subscription.yearly")}</b>
            </Button>
            <Button
              isOutline={sequenceType !== SubscriptionSequence.Quarterly}
              onClick={() => setSequenceType(SubscriptionSequence.Quarterly)}
              style={{ borderRadius: "50px" }}
              color="#130C60"
            >
              <b>{t("settings:business.subscription.quarterly")}</b>
            </Button>
            <div
              style={{
                width: "1px",
                height: "20px",
                backgroundColor: Colors.DarkGray,
                margin: "auto 10px",
              }}
            />
            <Button
              isOutline={sequenceType !== SubscriptionSequence.Monthly}
              onClick={() => setSequenceType(SubscriptionSequence.Monthly)}
              style={{ borderRadius: "50px" }}
              color="#130C60"
            >
              <b>{t("settings:business.subscription.monthly")}</b>
            </Button>
          </div>
        </div>
      </Row>

      <Row className="pt-3">
        <Col className="d-flex flex-row gap-3 justify-content-center flex-wrap">
          {!showAsUpgrade && (
            <SubscriptionBasic
              displaySequenceType={sequenceType}
              activeSubscription={activeSubscription}
              handleNextStep={handleNextStepWrapper}
            />
          )}

          {!(
            showAsUpgrade &&
            activeSubscription?.type === SubscriptionType.Growing
          ) && (
            <SubscriptionGrowing
              displaySequenceType={sequenceType}
              activeSubscription={activeSubscription}
              handleNextStep={handleNextStepWrapper}
              {...(showAsUpgrade && {
                showAsUpgradeFrom: activeSubscription?.type,
              })}
            />
          )}

          <SubscriptionProfessional
            displaySequenceType={sequenceType}
            activeSubscription={activeSubscription}
            handleNextStep={handleNextStepWrapper}
            {...(showAsUpgrade && {
              showAsUpgradeFrom: activeSubscription?.type,
            })}
          />
        </Col>
      </Row>

      {(activeSubscription?.type === SubscriptionType.Trial ||
        activeSubscription?.type === SubscriptionType.Free) && (
        <Row>
          <Col className="d-flex justify-content-center">
            <SubscriptionFree
              activeSubscription={activeSubscription}
              handleNextStep={handleNextStepWrapper}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default SubscriptionList;
