import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Menu, Sidebar, SubMenu } from "react-pro-sidebar";
import { Link, useNavigate } from "react-router-dom";

import { Colors } from "../../../../@types/colors";
import { ModuleType, Modules } from "../../../Modules";
import { Permissions } from "../../../Permissions";
import AuthContext from "../../../store/auth-context";
import NavigationItem from "./NavigationItem";
import NavigationTitle from "./NavigationTitle";

interface Props {
  isMenuBroken: boolean;
  isMenuCollapsed: boolean;
  isMenuToggled: boolean;
  setIsMenuToggled: (value: boolean) => void;
  setIsMenuBroken: (value: boolean) => void;
}

const Navigation = ({
  isMenuBroken,
  isMenuCollapsed,
  isMenuToggled,
  setIsMenuBroken,
  setIsMenuToggled,
}: Props) => {
  const authContext = useContext(AuthContext);
  const { t } = useTranslation(["modules", "settings"]);
  const navigate = useNavigate();

  const handleOnClickMenu = (link: string) => {
    navigate(link);
    isMenuBroken && setIsMenuToggled(false);
  };

  return (
    <Sidebar
      collapsed={isMenuCollapsed}
      toggled={isMenuToggled}
      onBackdropClick={() => setIsMenuToggled(false)}
      onBreakPoint={setIsMenuBroken}
      backgroundColor="white"
      breakPoint="md"
      className="sidebar shadow-lg"
      width="300px"
    >
      <Link to="/dashboard" className="mb-2 mt-2 text-center">
        <img
          src={isMenuCollapsed ? "/logo-square.svg" : "/logo.svg"}
          width={isMenuCollapsed ? "67px" : "200px"}
          className="my-3 p-2"
          alt="RestoManager logo"
          style={{ display: "block", margin: "auto" }}
        />
      </Link>

      <Menu
        menuItemStyles={{
          button: ({ active, level }) => {
            return {
              color: active ? Colors.DarkBlue : Colors.Black,
              backgroundColor: active ? Colors.LightBlue : undefined,

              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: isMenuCollapsed ? 5 : level === 0 ? 10 : 30,
              paddingRight: isMenuCollapsed ? 5 : level === 0 ? 10 : 30,

              marginLeft: 15,
              marginRight: 15,
              marginTop: 5,
              marginBottom: 5,

              borderRadius: 7,
              height: 40,
              "&:hover": {
                backgroundColor: active ? Colors.LightBlue : undefined,
              },
            };
          },
        }}
      >
        <NavigationItem
          link="/dashboard"
          icon="fa-chart-line"
          onClick={handleOnClickMenu}
        >
          {t(`modules:dashboard.title`)}
        </NavigationItem>

        {!isMenuCollapsed ? (
          <NavigationTitle>{t("modules:title")}</NavigationTitle>
        ) : (
          <hr />
        )}

        {Modules.filter((module) =>
          authContext!.hasModule(module.moduleType)
        ).map(
          (module) =>
            authContext!.hasPermission(module.permission) && (
              <NavigationItem
                key={module.path}
                link={module.path}
                icon={module.icon}
                onClick={handleOnClickMenu}
              >
                {t(`modules:${module.key}.title`)}
              </NavigationItem>
            )
        )}

        {authContext?.hasPermission(Permissions.businessManage) && (
          <>
            {!isMenuCollapsed ? (
              <NavigationTitle>{t("settings:title")}</NavigationTitle>
            ) : (
              <hr />
            )}

            <NavigationItem
              icon="fa fa-calendar-days"
              link="/settings/business/openinghours"
              onClick={handleOnClickMenu}
            >
              {t("settings:business.openingHours.title")}
            </NavigationItem>

            {authContext!.hasModule(ModuleType.Reservations) &&
              authContext!.hasPermission(Permissions.floorManage) && (
                <NavigationItem
                  link="/settings/floors"
                  icon="fa-pen-ruler"
                  onClick={handleOnClickMenu}
                >
                  {t(`settings:floors.title`)}
                </NavigationItem>
              )}

            <NavigationItem
              icon="fa fa-sitemap"
              link="/settings/business/modules"
              onClick={handleOnClickMenu}
            >
              {t("settings:business.modules.title")}
            </NavigationItem>

            {/**authContext!.hasModule(ModuleType.DigitalMenu) &&
                authContext?.hasPermission(Permissions.menuManage) && (
                  <NavigationItem
                  link="/settings/menus"
                  icon="fa-solid fa-book-open"
                  onClick={handleOnClickMenu}
                  >
                  {t(`settings:menus.title`)}
                  </NavigationItem>
                )**/}

            {authContext!.hasModule(ModuleType.Reservations) && (
              <NavigationItem
                link="/settings/specials"
                icon="fa-solid fa-champagne-glasses"
                onClick={handleOnClickMenu}
              >
                {t(`settings:specials.title`)}
              </NavigationItem>
            )}

            {authContext!.hasPermission(Permissions.templateManage) && (
              <NavigationItem
                link="/settings/templates"
                icon="fa-solid fa-file-pen"
                onClick={handleOnClickMenu}
              >
                {t(`settings:templates.title`)}
              </NavigationItem>
            )}

            <SubMenu
              icon={<i className={`fa fa-cog`} style={{ fontSize: "20px" }} />}
              label={t(`settings:administration`)}
            >
              <NavigationItem
                link="/settings/business/general"
                icon="fa-store"
                onClick={handleOnClickMenu}
              >
                {t(`settings:business.general.title`)}
              </NavigationItem>

              {authContext!.hasPermission(Permissions.paymentSetup) && (
                <NavigationItem
                  icon="fa fa-receipt"
                  link="/settings/business/payments"
                  onClick={handleOnClickMenu}
                >
                  {t("settings:business.onlinePayments.title")}
                </NavigationItem>
              )}

              {authContext!.hasPermission(Permissions.userManage) && (
                <NavigationItem
                  link="/settings/users"
                  icon="fa-user-friends"
                  onClick={handleOnClickMenu}
                >
                  {t(`settings:users.title`)}
                </NavigationItem>
              )}

              <NavigationItem
                link="/settings/integrations"
                icon="fa-solid fa-link"
                onClick={handleOnClickMenu}
              >
                {t(`settings:integrations.title`)}
              </NavigationItem>

              <NavigationItem
                icon="fa fa-bell"
                link="/settings/business/notifications"
                onClick={handleOnClickMenu}
              >
                {t("settings:business.notifications.title")}
              </NavigationItem>

              <NavigationItem
                icon="fa fa-code"
                link="/settings/business/frames"
                onClick={handleOnClickMenu}
              >
                {t("settings:business.frames.title")}
              </NavigationItem>

              <NavigationItem
                icon="fa fa-credit-card"
                link="/settings/business/subscription"
                onClick={handleOnClickMenu}
              >
                {t("settings:business.subscription.title")}
              </NavigationItem>
            </SubMenu>
          </>
        )}

        {authContext?.role === "Admin" && (
          <>
            {!isMenuCollapsed ? (
              <NavigationTitle>Administrator</NavigationTitle>
            ) : (
              <hr />
            )}
            <NavigationItem
              link="/admin/businesses"
              icon="fa-folder"
              onClick={handleOnClickMenu}
            >
              Alle zaken
            </NavigationItem>
            <NavigationItem
              link="/admin/logs"
              icon="fa-database"
              onClick={handleOnClickMenu}
            >
              Logs
            </NavigationItem>
            <NavigationItem
              link="/admin/promotionalcodes"
              icon="fa-percent"
              onClick={handleOnClickMenu}
            >
              Promotie Codes
            </NavigationItem>
          </>
        )}
      </Menu>
    </Sidebar>
  );
};

export default Navigation;
