import { useContext, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";

import AuthContext from "../../store/auth-context";
import TrialEndLayout from "./TrialEndLayout";

import useSearchQuery from "../../hooks/useSearchQuery";
import Navigation from "./navigation/Navigation";
import NavigationTop from "./navigation/NavigationTop";
import { ToastContainer } from "./ui";
import BusinessDisabledLayout from "./BusinessDisabledLayout";
import { getLocalstorageItem } from "../../services/localstorage-service";

const Layout = () => {
  const { t } = useTranslation([
    "modules",
    "settings",
    "account",
    "external",
    "general",
  ]);
  const authContext = useContext(AuthContext);

  const location = useLocation();
  const query = useSearchQuery();

  const [isMenuBroken, setIsMenuBroken] = useState(false);
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
  const [isMenuToggled, setIsMenuToggled] = useState(false);

  const locationSplit = location.pathname.split("/");
  const disableNavigation =
    query.get("disableNavigation") === "true" ||
    getLocalstorageItem("disableNavigation") === "true";

  const hasTrialEnded =
    authContext?.business.trialEnd &&
    new Date(authContext.business.trialEnd) <= new Date();

  if (locationSplit.length > 1 && locationSplit[1] && locationSplit[2]) {
    document.title =
      t(`${locationSplit[1]}:${locationSplit[2]}.title`) + " - RestoManager";

    if (locationSplit[1] === "admin") {
      document.title = "Admin - RestoManager";
    }
  } else {
    document.title = "Mijn RestoManager";
  }

  if (authContext && !authContext?.business.isEnabled) {
    return <BusinessDisabledLayout onLogout={authContext.logout} />;
  }

  if (hasTrialEnded) {
    return <TrialEndLayout onLogout={authContext.logout} />;
  }

  return (
    <div className="d-flex" style={{ minHeight: "100%" }}>
      {!disableNavigation && (
        <Navigation
          isMenuBroken={isMenuBroken}
          isMenuCollapsed={isMenuCollapsed}
          isMenuToggled={isMenuToggled}
          setIsMenuBroken={setIsMenuBroken}
          setIsMenuToggled={setIsMenuToggled}
        />
      )}

      <Container
        fluid
        style={{
          backgroundColor: "#EFEFEF",
          margin: "0",
          padding: "0",
          paddingTop: disableNavigation ? "15px" : "0",
          maxHeight: "100dvh",
          overflowY: "auto",
        }}
      >
        {!disableNavigation && (
          <NavigationTop
            business={authContext!.business}
            onMenuClose={() => {
              if (isMenuBroken) {
                setIsMenuToggled((prev) => !prev);
              } else {
                setIsMenuCollapsed((prev) => !prev);
              }
            }}
          />
        )}
        <Outlet />
        <ToastContainer />
      </Container>
    </div>
  );
};

export default Layout;
