interface Props {
  children: string;
}

const NavigationTitle = ({ children }: Props) => {
  return (
    <div
      className="ms-4 mt-3 mb-1 text-muted text-uppercase"
      style={{ fontSize: "14px" }}
    >
      {children}
    </div>
  );
};

export default NavigationTitle;
