export enum Permissions {
  clientList = "clientList",
  clientManage = "clientManage",
  clientSegmentList = "clientSegmentList",
  clientSegmentManage = "clientSegmentManage",

  voucherList = "voucherList",
  voucherManage = "voucherManage",
  voucherVerify = "voucherVerify",

  digitalMenuManage = "digitalMenuManage",
  digitalMenuQrRequest = "digitalMenuQrRequest",

  newsletterList = "newsletterList",
  newsletterSchedule = "newsletterSchedule",

  reservationList = "reservationList",
  reservationManage = "reservationManage",

  floorManage = "floorManage",

  businessManage = "businessManage",

  userManage = "userManage",
  userPermissionsManage = "userPermissionsManage",

  templateManage = "templateManage",

  paymentSetup = "paymentSetup",
  paymentList = "paymentList",

  reviewList = "reviewList",
  reviewManage = "reviewManage",

  menuManage = "menuManage",
}
