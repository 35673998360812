import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface Props {
  location?: string;
  pageTitle?: string;
  onClick?: () => void;
}

const BackButton = ({ location = "../", pageTitle, onClick }: Props) => {
  const { t } = useTranslation(["general"]);

  if (onClick) {
    return (
      <Row>
        <Col className="ms-2 my-1 mb-2">
          <small
            style={{ cursor: "pointer", color: "#4E6EFB" }}
            onClick={onClick}
          >
            {"< " +
              (pageTitle
                ? t("general:navigation.backTo", { page: pageTitle })
                : t("general:navigation.back"))}
          </small>
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col className="ms-2 my-1 mb-2">
        <Link to={location} style={{ textDecoration: "none" }}>
          <small>
            {"< " +
              (pageTitle
                ? t("general:navigation.backTo", { page: pageTitle })
                : t("general:navigation.back"))}
          </small>
        </Link>
      </Col>
    </Row>
  );
};

export default BackButton;
