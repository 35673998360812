import { Col, Row } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { Colors } from "../../../@types/colors";
import { SubscriptionType } from "../../../@types/subscription";
import { Button, Card } from "../layout/ui";

interface Props {
  currentSubscription: SubscriptionType;
  onCancel: () => void;
}

const SubscriptionCancel = ({ currentSubscription, onCancel }: Props) => {
  const { t } = useTranslation(["settings"]);
  return (
    <Row>
      <Col>
        <Card
          title={t("settings:business.subscription.cancel.title")}
          subtitle={t("settings:business.subscription.cancel.subtitle")}
          icon="fas fa-times-circle"
        >
          <p>{t("settings:business.subscription.cancel.info")}</p>
          <p>
            <Trans
              i18nKey="settings:business.subscription.cancel.info2"
              components={[
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  href="https://restomanager.net/legal/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                />,
              ]}
            />
          </p>
          <Button color={Colors.Danger} onClick={onCancel}>
            {t("settings:business.subscription.cancel.confirmButton")}
          </Button>
        </Card>
      </Col>
    </Row>
  );
};

export default SubscriptionCancel;
