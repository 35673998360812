import { DateTime } from "luxon";
import { Badge, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { Business } from "../../../../@types/business";
import { BusinessPicker, Card, IconButton } from "../ui";

interface Props {
  business: Business;
  onMenuClose: () => void;
}

const NavigationTop = ({ business, onMenuClose }: Props) => {
  const { t } = useTranslation(["general"]);
  const navigate = useNavigate();

  const trialRemaining =
    business.trialEnd &&
    Math.floor(
      DateTime.fromISO(business.trialEnd).diff(DateTime.now(), "days").days + 1
    );

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card disablePadding className="p-2 mt-2 mb-3">
            <Row className="d-flex align-items-center justify-content-center">
              <Col>
                <IconButton
                  icon="fas fa-bars"
                  onClick={onMenuClose}
                  style={{ fontSize: "22px" }}
                  data-test="navigation-menu-button"
                />
              </Col>

              <Col className="text-center d-flex flex-column">
                <BusinessPicker />

                {trialRemaining && trialRemaining > 0 && (
                  <div>
                    <Badge data-test="navigation-trial-remaining">
                      {t("general:trial.remaining", {
                        count: trialRemaining,
                        days: trialRemaining,
                      })}
                    </Badge>
                  </div>
                )}
              </Col>

              <Col className="d-flex align-items-center justify-content-end me-1">
                <IconButton
                  icon="fas fa-circle-question"
                  className="text-muted d-none d-md-block"
                  style={{ fontSize: "22px" }}
                  onClick={() =>
                    window.open("https://restomanager.net/support/", "_blank")
                  }
                  data-test="navigation-help-button"
                />
                <IconButton
                  icon="fas fa-user"
                  style={{ fontSize: "22px" }}
                  onClick={() => navigate("/account")}
                  data-test="navigation-account-button"
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default NavigationTop;
