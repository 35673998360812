import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Business } from "../../../@types/business";
import { Button, Select } from "../layout/ui";

interface Props {
  businesses: Business[];
  onChoose: (business: Business) => void;
  isLoading: boolean;
}

const LoginBusinesses = ({ businesses, onChoose, isLoading }: Props) => {
  const { t } = useTranslation(["account"]);

  const [chosenBusiness, setChosenBusiness] = useState<Business | null>(
    businesses.length > 0 ? businesses[0] : null
  );

  const onSubmitHandler = (event: any) => {
    event.preventDefault();

    chosenBusiness && onChoose(chosenBusiness);
  };

  return (
    <>
      <Row className="text-center">
        <Col>
          <h4 className="text-white">{t("login.chooseBusiness.title")}</h4>
        </Col>
      </Row>

      <Row className="text-center">
        <Col>
          <Select
            options={businesses.map((business) => ({
              title: business.name,
              value: business.id,
            }))}
            onChange={(event: any) => {
              setChosenBusiness(
                businesses.find((b) => b.id === event.target.value) ?? null
              );
            }}
          />

          <Button
            isBlock
            data-test="choose-business"
            style={{ borderColor: "white" }}
            isLoading={isLoading}
            disabled={isLoading}
            onClick={onSubmitHandler}
          >
            {t("login.chooseBusiness.next")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default LoginBusinesses;
