import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Subscription,
  SubscriptionSequence,
  SubscriptionType,
} from "../../../../@types/subscription";
import { Card, MoneyFormat } from "../../layout/ui";
import SubscriptionButton from "../SubscriptionButton";
import SubscriptionFeature from "../SubscriptionFeature";
import { PRICING } from "./Packages";
import { formatMoney } from "../../layout/ui/MoneyFormat";

export const SubscriptionFeaturesBasic = () => {
  return (
    <>
      <SubscriptionFeature featureKey="reservations" />
      <SubscriptionFeature featureKey="vouchers" />
      <SubscriptionFeature featureKey="reserveWithGoogle" />
      <SubscriptionFeature featureKey="marketingCampaigns" amount={5000} />
      <SubscriptionFeature featureKey="amountPerOnlinePayment" amount={1.99} />
      <SubscriptionFeature featureKey="noComissionPerCover" />
    </>
  );
};

interface SubscriptionItemProps {
  displaySequenceType: SubscriptionSequence;
  activeSubscription?: Subscription;
  handleNextStep?: (subscriptionType: SubscriptionType) => void;
}

const SubscriptionBasic = ({
  displaySequenceType,
  activeSubscription,
  handleNextStep,
}: SubscriptionItemProps) => {
  const { t } = useTranslation(["settings", "general"]);

  const displayPrice = useMemo(() => {
    return displaySequenceType === SubscriptionSequence.Monthly
      ? PRICING.BASIC.MONTHLY
      : PRICING.BASIC.MONTHLY_PAID_ANNUALLY;
  }, [displaySequenceType]);

  return (
    <Card
      title={t("settings:business.subscription.type.basic")}
      subtitle={t("settings:business.subscription.type.basicInfo")}
      className="mb-3 w-100"
      forCurrent={activeSubscription?.type === SubscriptionType.Basic}
      style={{ maxWidth: "350px" }}
    >
      <div
        className="d-flex flex-column justify-content-between"
        style={{ minHeight: "410px" }}
      >
        <div className="d-flex flex-column align-items-center mb-3">
          <h3 className="m-0">
            <MoneyFormat value={displayPrice} useDecimals={false} />
          </h3>
          <small className="text-muted">
            {t("settings:business.subscription.perMonth")}
          </small>
        </div>

        <div className="d-flex flex-column gap-2 mb-4">
          <SubscriptionFeaturesBasic />
        </div>

        <div>
          {displaySequenceType === SubscriptionSequence.Quarterly && (
            <div className="d-flex justify-content-center">
              <small>
                {t("settings:business.subscription.quarterTotal", {
                  amount: formatMoney(PRICING.BASIC.QUARTERLY, "EUR", false),
                })}
              </small>
            </div>
          )}
          {displaySequenceType === SubscriptionSequence.Yearly && (
            <div className="d-flex justify-content-center">
              <small>
                {t("settings:business.subscription.yearTotal", {
                  amount: formatMoney(PRICING.BASIC.YEARLY, "EUR", false),
                })}
              </small>
            </div>
          )}
          {handleNextStep && (
            <SubscriptionButton
              currentSubscription={activeSubscription}
              forSubscription={SubscriptionType.Basic}
              handleNextStep={handleNextStep}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default SubscriptionBasic;
