import { useContext, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { PromotionalCodeType } from "../../../@types/promotionalCode";
import {
  Subscription,
  SubscriptionSequence,
  SubscriptionType,
} from "../../../@types/subscription";
import { SelectedSubscriptionModel } from "../../pages/settings/business/Subscription";
import AuthContext from "../../store/auth-context";
import { Button, Card, Input, MoneyFormat } from "../layout/ui";
import { CalculcateSubscriptionPrice } from "./packages/Packages";

interface SubscriptionCheckoutProps {
  selectedSubscription: SelectedSubscriptionModel;
  handlePromotionalCode: (code: string) => void;
  handleCheckout: (currentSubscriptionId?: string) => void;
  activeSubscription?: Subscription;
  showAsUpgradeFrom?: SubscriptionType;
  isLoading?: boolean;
}

const SubscriptionCheckout = ({
  selectedSubscription,
  handlePromotionalCode,
  handleCheckout,
  activeSubscription,
  showAsUpgradeFrom,
  isLoading,
}: SubscriptionCheckoutProps) => {
  const authContext = useContext(AuthContext);
  const { t } = useTranslation(["settings"]);

  const [promotionalCode, setPromotionalCode] = useState<string>();

  const typeString = t(
    `settings:business.subscription.type.${SubscriptionType[
      selectedSubscription.type
    ].toLowerCase()}`
  );

  const sequenceString = t(
    `settings:business.subscription.${SubscriptionSequence[
      selectedSubscription.sequence
    ].toLowerCase()}`
  );

  const calculatedPrice = useMemo(() => {
    if (activeSubscription) {
      return CalculcateSubscriptionPrice(
        selectedSubscription,
        activeSubscription
      );
    }

    return CalculcateSubscriptionPrice(selectedSubscription);
  }, [selectedSubscription]);

  const onHandlePromotionalCode = () => {
    promotionalCode && handlePromotionalCode(promotionalCode);
  };

  const onHandleCheckout = () => {
    const currentSubscriptionId = activeSubscription
      ? activeSubscription.id
      : undefined;
    handleCheckout(currentSubscriptionId);
  };

  const isUpgrade =
    showAsUpgradeFrom && calculatedPrice.upgradeSubtractionWithVat > 0;
  const shouldHaveVat = authContext!.business.address?.country === "Belgium";
  const total = shouldHaveVat
    ? isUpgrade
      ? calculatedPrice.totalWithVat - calculatedPrice.upgradeSubtractionWithVat
      : calculatedPrice.totalWithVat
    : isUpgrade
    ? calculatedPrice.total - calculatedPrice.upgradeSubtractionWithVat
    : calculatedPrice.total;

  return (
    <>
      <Row>
        <Col sm={12} md={8} className="mb-3">
          <Card title={t("settings:business.subscription.title")}>
            <div className="d-flex flex-column gap-1">
              <div className="d-flex justify-content-between">
                <span>
                  {typeString} - {sequenceString}
                </span>

                <b>
                  <MoneyFormat value={calculatedPrice.base} />
                </b>
              </div>

              {selectedSubscription.promotionalCode && (
                <div className="d-flex justify-content-between">
                  <span>
                    {t("settings:business.subscription.promotionCode")}
                  </span>

                  <b>
                    -{" "}
                    {selectedSubscription.promotionalCode.type ===
                    PromotionalCodeType.Amount ? (
                      <MoneyFormat
                        value={selectedSubscription.promotionalCode.amount}
                      />
                    ) : (
                      `${selectedSubscription.promotionalCode.amount}%`
                    )}
                  </b>
                </div>
              )}

              <div className="d-flex justify-content-between">
                <span>
                  {t("settings:business.subscription.vat")} (
                  {shouldHaveVat ? "21%" : "0%"})
                </span>

                <MoneyFormat value={shouldHaveVat ? calculatedPrice.vat : 0} />
              </div>

              {isUpgrade && (
                <>
                  <div className="d-flex justify-content-between">
                    <b>{t("settings:business.subscription.subtotal")}</b>
                    <b>
                      <MoneyFormat
                        value={
                          shouldHaveVat
                            ? calculatedPrice.totalWithVat
                            : calculatedPrice.total
                        }
                      />
                    </b>
                  </div>

                  <div className="d-flex justify-content-between">
                    <span>
                      {t(
                        "settings:business.subscription.upgradeSubscription.previousSubscription"
                      )}{" "}
                      {t(
                        `settings:business.subscription.type.${SubscriptionType[
                          showAsUpgradeFrom
                        ].toLowerCase()}`
                      )}
                    </span>

                    <span>
                      -{" "}
                      <MoneyFormat
                        value={calculatedPrice.upgradeSubtractionWithVat}
                      />
                    </span>
                  </div>
                </>
              )}
              <hr />

              <div className="d-flex justify-content-between">
                <b>{t("settings:business.subscription.totalToPayNow")}</b>

                <b>
                  <MoneyFormat value={total > 0 ? total : 0} />
                </b>
              </div>

              {isUpgrade && total > 0 && (
                <small className="text-muted w-75 pt-3">
                  {t(
                    "settings:business.subscription.upgradeSubscription.activateInfo"
                  )}
                </small>
              )}
            </div>
          </Card>
        </Col>

        <Col sm={12} md={4}>
          <Card title={t("settings:business.subscription.promotionCode")}>
            <Input
              placeholder={t(
                "settings:business.subscription.promotionCodePlaceholder"
              )}
              onChange={(e: any) => setPromotionalCode(e.target.value)}
            />
            <div className="input-group-append">
              <Button isBlock isOutline onClick={onHandlePromotionalCode}>
                {t("settings:users.add.title")}
              </Button>
            </div>
          </Card>
        </Col>
      </Row>

      <Row className="mt-3">
        <Button
          isBlock
          onClick={onHandleCheckout}
          isLoading={isLoading}
          disabled={isLoading}
        >
          <i className="fa fa-lock me-2" />
          {t(
            isUpgrade
              ? "settings:business.subscription.upgradeSubscription.activate"
              : "settings:business.subscription.goToPayment"
          )}
        </Button>

        <div className="text-center">
          <small className="text-muted">
            <Trans
              i18nKey="general:conditions"
              components={[
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  href="https://restomanager.net/legal/betalingsvoorwaarden-abonnementen/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                />,
              ]}
            />
          </small>
        </div>
      </Row>

      <Row className="mb-3">
        <small className="text-muted mt-4 text-center">
          <b>{t("settings:business.subscription.paymentSecure")}</b>
          <div className="d-flex gap-1 justify-content-center">
            <img src="/icons/payment/applepay.svg" alt="ApplePay" />
            <img src="/icons/payment/bancontact.svg" alt="Bancontact" />
            <img src="/icons/payment/belfius.svg" alt="Befius" />
            <img src="/icons/payment/kbc.svg" alt="KBC" />
            <img src="/icons/payment/maestro.svg" alt="Maestro" />
            <img src="/icons/payment/visa.svg" alt="Visa" />
            <img src="/icons/payment/banktransfer.svg" alt="Banktransfer" />
            <img src="/icons/payment/klarna.svg" alt="Klarna" />
          </div>
        </small>
      </Row>
    </>
  );
};

export default SubscriptionCheckout;
