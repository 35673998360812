import { useState, useContext } from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import LoginEmailPassword from "../components/login/LoginEmailPassword";
import LoginTwoFa from "../components/login/LoginTwoFa";
import LoginBusinesses from "../components/login/LoginBusinesses";
import AuthContext from "../store/auth-context";
import useFetch from "../hooks/useFetch";
import { Business } from "../../@types/business";

enum LoginState {
  EmailPassword,
  TwoFa,
  ChooseBusiness,
}

const Login = () => {
  const { t } = useTranslation(["account"]);
  const authContext = useContext(AuthContext);

  const [loginState, setLoginState] = useState<LoginState>(
    LoginState.EmailPassword
  );

  const [token, setToken] = useState<string | null>(null);

  const [mail, setMail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);

  const [error, setError] = useState<string | null>(null);

  const { fetchData: postLogin, loading: isLoginLoading } = useFetch(
    "POST",
    ["account", "login"],
    false
  );
  const { fetchData: getAllBusinessesForUser, loading: isBusinessLoading } =
    useFetch("GET", ["business"], false);

  const onLoginHandler = (email: string, password: string) => {
    postLogin({}, { email, password }).then((response) => {
      if (response.status === 200) {
        setLoginState(LoginState.TwoFa);
        setMail(email);
        setPassword(password);
        setError(null);
        return response.json();
      } else {
        setError(t("errors.emailPassword"));
        return null;
      }
    });
  };

  const onLoginFinishHandler = (code: string) => {
    postLogin({}, { email: mail, password: password, twoFA: code })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          setError(t("errors.wrong2Fa"));
          return null;
        }
      })
      .then((newToken) => {
        if (newToken) {
          setToken(newToken);
          setError(null);

          getAllBusinessesForUser({}, {}, newToken)
            .then((response) => response.json())
            .then((response: Business[] | null) => {
              const businesses = response?.filter((b) => b && b.isEnabled);

              if (businesses && businesses.length > 0) {
                if (businesses.length === 1) {
                  authContext!.login(newToken, businesses[0]);
                  return;
                }

                authContext!.setBusinessList(businesses);
                setLoginState(LoginState.ChooseBusiness);
              } else {
                setError(t("errors.noBusinesses"));
              }
            });
        }
      });
  };

  const onChooseBusinessHandler = (business: Business) => {
    token && authContext!.login(token, business);
  };

  return (
    <>
      {error && (
        <Alert variant="danger">
          <div className="text-center">{error}</div>
        </Alert>
      )}

      {loginState === LoginState.EmailPassword && (
        <LoginEmailPassword
          onLogin={onLoginHandler}
          isLoading={isLoginLoading}
        />
      )}

      {loginState === LoginState.TwoFa && (
        <LoginTwoFa
          onLoginFinish={onLoginFinishHandler}
          isLoading={isLoginLoading}
        />
      )}

      {loginState === LoginState.ChooseBusiness && (
        <LoginBusinesses
          businesses={authContext!.businessList}
          onChoose={onChooseBusinessHandler}
          isLoading={isBusinessLoading}
        />
      )}
    </>
  );
};

export default Login;
