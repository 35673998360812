import { lazy } from "react";
import {
  getLocalstorageItem,
  setLocalstorageItem,
} from "../services/localstorage-service";

const lazyLoad = (componentImport: any) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      getLocalstorageItem("pageHasBeenForceRefreshed") || "false"
    );

    try {
      const component = await componentImport();

      setLocalstorageItem("pageHasBeenForceRefreshed", "false");

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        setLocalstorageItem("pageHasBeenForceRefreshed", "true");
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

export default lazyLoad;
