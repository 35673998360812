import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Field, Form } from "react-final-form";

import { Button, Input } from "../layout/ui";
import Validators from "../../utils/Validators";

interface Props {
  onLoginFinish: (code: string) => void;
  isLoading: boolean;
}

const LoginTwoFa = ({ onLoginFinish, isLoading }: Props) => {
  const { t } = useTranslation(["account"]);

  const onSubmitHandler = (values: { code: string }) => {
    onLoginFinish(values.code);
  };

  return (
    <>
      <Row className="text-center text-white">
        <Col>
          <h3>
            <i
              className="fas fa-envelope"
              style={{ fontSize: "100px", color: "white" }}
            />
          </h3>
          <p>{t("login.twoFa.title")}</p>
        </Col>
      </Row>

      <Row className="text-center">
        <Col>
          <Form
            onSubmit={onSubmitHandler}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="code"
                  validate={Validators.compose(
                    Validators.required(),
                    Validators.maxLength(6)
                  )}
                >
                  {({ input, meta }) => (
                    <Input
                      {...input}
                      meta={meta}
                      type="text"
                      placeholder={t("login.twoFa.yourCode")}
                      style={{ height: "45px" }}
                      autoFocus
                    />
                  )}
                </Field>

                <Button
                  type="submit"
                  isBlock
                  className="mt-3"
                  data-test="login-twofa"
                  style={{ borderColor: "white" }}
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  {t("login.twoFa.login")}
                </Button>
              </form>
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default LoginTwoFa;
