import { Button } from ".";
import { useTranslation } from "react-i18next";

const CallUs = () => {
  const { t } = useTranslation(["general"]);

  const onCallHandler = () => {
    window.location.href = "tel:003292774592";
  };

  return (
    <Button className="px-3" isOutline onClick={onCallHandler}>
      <div className="d-flex flex-row align-items-center">
        <i className="fa fa-phone me-3" />
        <div>{t("general:needHelp") as string}</div>
      </div>
    </Button>
  );
};

export default CallUs;
