import { DateTime } from "luxon";
import { Col, Row } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import {
  Subscription,
  SubscriptionSequence,
  SubscriptionType,
} from "../../../@types/subscription";
import { Button, Card, MoneyFormat } from "../layout/ui";
import { SubscriptionFeaturesBasic } from "./packages/SubscriptionBasic";
import { SubscriptionFeaturesGrowing } from "./packages/SubscriptionGrowing";
import { SubscriptionFeaturesProfessional } from "./packages/SubscriptionProfessional";

interface Props {
  activeSubscription: Subscription;
  onUpgradeSubscription: () => void;
}

const CurrentSubscription = ({
  activeSubscription,
  onUpgradeSubscription,
}: Props) => {
  const { t } = useTranslation(["settings"]);

  const sequenceString = t(
    `settings:business.subscription.${SubscriptionSequence[
      activeSubscription.sequence
    ].toLowerCase()}`
  );

  return (
    <Row className="justify-content-center">
      <Col md={5} className="mb-3">
        <Card
          title={t("settings:business.subscription.currentSubscription.title")}
        >
          <p>
            <Trans
              i18nKey="settings:business.subscription.currentSubscription.description1"
              components={[<b />]}
              values={{
                type: SubscriptionType[activeSubscription.type],
              }}
            />
          </p>

          {activeSubscription.type === SubscriptionType.Basic && (
            <SubscriptionFeaturesBasic />
          )}
          {activeSubscription.type === SubscriptionType.Growing && (
            <SubscriptionFeaturesGrowing showAllFeatures />
          )}
          {activeSubscription.type === SubscriptionType.Professional && (
            <SubscriptionFeaturesProfessional showAllFeatures />
          )}
        </Card>
      </Col>
      <Col md={3} className="mb-3">
        <Card
          title={t(
            "settings:business.subscription.currentSubscription.invoice"
          )}
        >
          <p>
            <Trans
              i18nKey="settings:business.subscription.currentSubscription.description2"
              components={[
                <b />,
                <MoneyFormat value={activeSubscription.nextDueTotal} />,
              ]}
              values={{
                date: DateTime.fromISO(
                  activeSubscription.nextDueDate!
                ).toFormat("dd/MM/yyyy"),
              }}
            />
          </p>
          <p>
            {t(
              "settings:business.subscription.currentSubscription.description3",
              {
                sequence: sequenceString.toLocaleLowerCase(),
              }
            )}
          </p>
        </Card>
      </Col>
      {activeSubscription.type !== SubscriptionType.Professional && (
        <Col md={4}>
          <Card
            style={{ border: "5px #7367f0 solid" }}
            title={t(
              "settings:business.subscription.upgradeSubscription.title"
            )}
            subtitle={t(
              "settings:business.subscription.upgradeSubscription.subtitle"
            )}
          >
            <Button isBlock onClick={onUpgradeSubscription}>
              {t(
                "settings:business.subscription.upgradeSubscription.seeOptions"
              )}
            </Button>
          </Card>
        </Col>
      )}
    </Row>
  );
};

export default CurrentSubscription;
