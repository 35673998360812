import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Subscription, SubscriptionType } from "../../../@types/subscription";
import { Button } from "../../components/layout/ui";

interface Props {
  currentSubscription?: Subscription;
  forSubscription: SubscriptionType;
  handleNextStep: (subscriptionType: SubscriptionType) => void;
}

const SubscriptionButton = ({
  currentSubscription,
  forSubscription,
  handleNextStep,
}: Props) => {
  const { t } = useTranslation(["settings", "general"]);
  const [isLoading, setIsLoading] = useState(false);

  const onClickHandler = () => {
    setIsLoading(true);
    handleNextStep(forSubscription);
  };

  return (
    <Button
      isBlock
      disabled={currentSubscription?.type === forSubscription || isLoading}
      onClick={onClickHandler}
      isLoading={isLoading}
    >
      {currentSubscription?.type === forSubscription ? (
        t("general:tags.current") + "!"
      ) : forSubscription === SubscriptionType.Free ? (
        <>
          <i className="fa fa-gift me-2" />
          {t("settings:business.subscription.activate")}
        </>
      ) : (
        <>
          <i className="fa fa-cart-shopping me-2" />
          {t("settings:business.subscription.goToCheckout")}
        </>
      )}
    </Button>
  );
};

export default SubscriptionButton;
