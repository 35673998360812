import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useSearchQuery from "../hooks/useSearchQuery";
import { Swal } from "../components/layout/ui";
import RequestPasswordForm from "../components/requestpassword/RequestPasswordForm";
import RequestPasswordEmailForm from "../components/requestpassword/RequestPasswordEmailForm";
import useFetch from "../hooks/useFetch";

const RequestPassword = () => {
  const { t } = useTranslation(["account"]);

  const navigate = useNavigate();
  const query = useSearchQuery();

  const token = query.get("token");
  const email = query.get("email");

  const { fetchData: updatePasswordRequest } = useFetch(
    "POST",
    ["account", "password", "reset", "request"],
    false
  );
  const { fetchData: updatePasswordRequestToken } = useFetch(
    "POST",
    ["account", "password", "reset"],
    false
  );

  const onRequestHandler = (values) => {
    updatePasswordRequest({ email: values.email }).then((response) => {
      if (response.status === 200) {
        Swal.fire({
          title: t("account:forgotPassword.emailSent"),
          icon: "success",
        }).then(() => {
          navigate("/");
        });
      } else {
        Swal.fire({
          title: t("account:forgotPassword.error.emailNotFound"),
          icon: "error",
        });
      }
    });
  };

  const onRequestTokenHandler = (values) => {
    updatePasswordRequestToken(
      {},
      {
        token: token,
        email: email,
        password: values.password,
        confirmPassword: values.confirmPassword,
      }
    ).then((response) => {
      if (response.status === 200) {
        Swal.fire({
          title: t("account:forgotPassword.success"),
          icon: "success",
        }).then(() => {
          navigate("/");
        });
      } else {
        Swal.fire({
          title: t("account:forgotPassword.error.expired"),
          icon: "error",
        });
      }
    });
  };

  return (
    <>
      <Row>
        <Col className="text-white">
          <h3>
            <b>{t("forgotPassword.title")}</b>
          </h3>
          <p>{t("forgotPassword.subtitle")}</p>
        </Col>
      </Row>

      {(!token || !email) && (
        <RequestPasswordEmailForm onSubmit={onRequestHandler} />
      )}

      {token && email && (
        <RequestPasswordForm onSubmit={onRequestTokenHandler} />
      )}
    </>
  );
};

export default RequestPassword;
