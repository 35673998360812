import { useContext, useState } from "react";

import TrialForm, { TrialFormValues } from "../components/trial/TrialForm";
import useFetch from "../hooks/useFetch";
import AuthContext from "../store/auth-context";
import { useTranslation } from "react-i18next";
import { Swal } from "../components/layout/ui";

const Trial = () => {
  const { t, i18n } = useTranslation();
  const authContext = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);

  const { fetchData: postBusinessTrial } = useFetch(
    "POST",
    ["business", "trial"],
    false
  );
  const { fetchData: postRegisterTrial } = useFetch(
    "POST",
    ["account", "register", "trial"],
    false
  );
  const { fetchData: getAllBusinessesForUser } = useFetch(
    "GET",
    ["business"],
    false
  );

  const onAddTrialHandler = (values: TrialFormValues) => {
    setIsLoading(true);

    postBusinessTrial(
      {},
      {
        name: values.name,
        email: values.email,
        language: i18n.language.slice(0, 2).toLocaleLowerCase(),
        timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return null;
        }
      })
      .then((business) => {
        if (business) {
          postRegisterTrial(
            {},
            {
              businessId: business.id,
              email: values.email,
              password: values.password,
              language: i18n.language.slice(0, 2).toLocaleLowerCase(),
            }
          )
            .then((response) => {
              if (response.status === 200) {
                return response.json();
              } else if (response.status === 400) {
                Swal.fire({
                  title: t("account:errors.alreadyExists"),
                  icon: "error",
                });
                setIsLoading(false);
                return null;
              } else {
                setIsLoading(false);
                return null;
              }
            })
            .then((token) => {
              if (token) {
                getAllBusinessesForUser({}, {}, token)
                  .then((response) => {
                    if (response.status === 200) {
                      return response.json();
                    } else {
                      setIsLoading(false);
                      return null;
                    }
                  })
                  .then((businesses) => {
                    if (businesses) {
                      setIsLoading(false);
                      authContext?.login(token, businesses[0]);
                    }
                  });
              }
            });
        }
      });
  };

  return <TrialForm onSubmit={onAddTrialHandler} isLoading={isLoading} />;
};

export default Trial;
