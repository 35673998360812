import { DateTime } from "luxon";
import { PromotionalCodeType } from "../../../../@types/promotionalCode";
import {
  Subscription,
  SubscriptionSequence,
  SubscriptionType,
} from "../../../../@types/subscription";
import { SelectedSubscriptionModel } from "../../../pages/settings/business/Subscription";

export const PRICING = {
  BASIC: {
    MONTHLY: 69,
    MONTHLY_PAID_ANNUALLY: 59,

    QUARTERLY: 177,

    YEARLY: 708,
  },
  GROWING: {
    MONTHLY: 99,
    MONTHLY_PAID_ANNUALLY: 79,

    QUARTERLY: 237,

    YEARLY: 948,
  },
  PROFESSIONAL: {
    MONTHLY: 159,
    MONTHLY_PAID_ANNUALLY: 149,

    QUARTERLY: 447,

    YEARLY: 1788,
  },
};

export const CalculcateSubscriptionPrice = (
  subscription: SelectedSubscriptionModel,
  subscriptionToUpgrade?: Subscription
) => {
  let base = 0;
  let total = 0;

  if (subscription.type === SubscriptionType.Basic) {
    if (subscription.sequence === SubscriptionSequence.Yearly)
      base = PRICING.BASIC.YEARLY;
    if (subscription.sequence === SubscriptionSequence.Quarterly)
      base = PRICING.BASIC.QUARTERLY;
    if (subscription.sequence === SubscriptionSequence.Monthly)
      base = PRICING.BASIC.MONTHLY;
  }

  if (subscription.type === SubscriptionType.Growing) {
    if (subscription.sequence === SubscriptionSequence.Yearly)
      base = PRICING.GROWING.YEARLY;
    if (subscription.sequence === SubscriptionSequence.Quarterly)
      base = PRICING.GROWING.QUARTERLY;
    if (subscription.sequence === SubscriptionSequence.Monthly)
      base = PRICING.GROWING.MONTHLY;
  }

  if (subscription.type === SubscriptionType.Professional) {
    if (subscription.sequence === SubscriptionSequence.Yearly)
      base = PRICING.PROFESSIONAL.YEARLY;
    if (subscription.sequence === SubscriptionSequence.Quarterly)
      base = PRICING.PROFESSIONAL.QUARTERLY;
    if (subscription.sequence === SubscriptionSequence.Monthly)
      base = PRICING.PROFESSIONAL.MONTHLY;
  }

  total = base;

  if (subscription.promotionalCode) {
    if (subscription.promotionalCode.type === PromotionalCodeType.Amount) {
      if (total >= subscription.promotionalCode.amount) {
        total -= subscription.promotionalCode.amount;
      }
    }

    if (subscription.promotionalCode.type === PromotionalCodeType.Percentage) {
      let totalToDeduct =
        ((100 - subscription.promotionalCode.amount) / 100) * total;

      if (total >= totalToDeduct) {
        total = totalToDeduct;
      }
    }
  }

  let upgradeSubtractionWithVat = 0;

  if (subscriptionToUpgrade) {
    const nextDueDate = DateTime.fromISO(subscriptionToUpgrade.nextDueDate!);
    const differenceInMonths =
      (nextDueDate.year - DateTime.now().year) * 12 +
      nextDueDate.month -
      DateTime.now().month;
    const divider =
      subscriptionToUpgrade.sequence === SubscriptionSequence.Yearly
        ? 12
        : subscriptionToUpgrade.sequence === SubscriptionSequence.Quarterly
        ? 3
        : 1;

    upgradeSubtractionWithVat =
      (subscriptionToUpgrade.nextDueTotal / divider) * (differenceInMonths - 1);
  }

  return {
    base,
    upgradeSubtractionWithVat,
    totalWithVat: total * 1.21,
    vat: total * 0.21,
    total: total,
  };
};
